<template>
  <div class="about">
 
  </div>
</template>

<script>
export default {
  name: "About",
  components: {},
  data() {
    return {

    };
  },
  methods: {
  },
  created() {},
  mounted() {

  },
};
</script>
<style scoped>
.about {
  /* width: 100vw;
  height: 100vh;
  overflow: hidden; */
  display: flex;
  padding: 1%;
}
</style>
